import './RemoveAccount.css';

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { isUserAuthenticatedWithBankid } from '../../core/authState.utils';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';
import RemoveAccountWithBankid from './bankid/RemoveAccountWithBankid';
import RemoveAccountWithEmail from './email/RemoveAccountWithEmail';
import Success from './Success';

const RemoveAccount = () => {
    const context = useLoginContext();
    const location = useLocation();
    return (
        <Routes>
            <Route path="bankid" element={<RemoveAccountWithBankid />} />
            <Route path="e-post" element={<RemoveAccountWithEmail />} />
            <Route path="klart" element={<Success />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: isUserAuthenticatedWithBankid(context.authState)
                                ? SpaRoutes.RemoveAccount.Bankid
                                : SpaRoutes.RemoveAccount.Email,
                            search: location.search,
                        }}
                    />
                }
            />
        </Routes>
    );
};

export default RemoveAccount;
