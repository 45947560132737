import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { SpaRoutes } from '../../hooks/useLoginNavigate';
import ConfirmBankId from './bankid/ConfirmBankid';
import ConfirmPassword from './password/ConfirmPassword';

const Confirm = () => {
    const location = useLocation();
    return (
        <Routes>
            <Route path="bankid" element={<ConfirmBankId />} />
            <Route path="losenord" element={<ConfirmPassword />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: SpaRoutes.Login.Start,
                            search: location.search,
                        }}
                    />
                }
            />
        </Routes>
    );
};
export default Confirm;
