import { RadioGroup } from '@coop/components';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';
import CreateAccountBankid from './bankid/CreateAccountBankid';
import CreateEmailAccount from './e-post/CreateEmailAccount';
import VerifyEmail from './e-post/verifiera-epost/Verifymail';

const headerRoutes = [
    SpaRoutes.CreateAccount.Private.BankId.Start,
    SpaRoutes.CreateAccount.Private.Email.Start,
];

const CreateNewAccountPrivate = () => {
    const navigate = useLoginNavigate();
    const location = useLocation();
    const onTypeChange = (value: string) => {
        if (value === 'bankid') {
            navigate(SpaRoutes.CreateAccount.Private.BankId.Start);
        } else {
            navigate(SpaRoutes.CreateAccount.Private.Email.Start);
        }
    };
    const isBankIdRoute = location.pathname.startsWith(
        SpaRoutes.CreateAccount.Private.BankId.Start,
    );

    // lets trim ending slash
    const path = location.pathname.replace(/\/$/, '');

    return (
        <Routes>
            <Route path="/e-post/verifiera" element={<VerifyEmail />} />
            <Route
                path="*"
                element={
                    <>
                        {headerRoutes.includes(path) && (
                            <>
                                <h1 className="Heading Heading--large u-textCenter">
                                    Skapa kundkonto
                                </h1>
                                <RadioGroup.Root
                                    onValueChange={onTypeChange}
                                    defaultValue={isBankIdRoute ? 'bankid' : 'email'}
                                >
                                    <div className="u-flex u-flexJustifyCenter u-gapSm u-marginTlg">
                                        <RadioGroup.Item
                                            id="bankid"
                                            value="bankid"
                                            label="Med BankID"
                                        />
                                        <RadioGroup.Item
                                            id="email"
                                            value="email"
                                            label="Med E-post"
                                        />
                                    </div>
                                </RadioGroup.Root>
                            </>
                        )}
                        <Routes>
                            <Route path={'/bankid/*'} element={<CreateAccountBankid />} />
                            <Route path={'/e-post/*'} element={<CreateEmailAccount />} />
                            <Route
                                path={'/*'}
                                element={
                                    <Navigate
                                        to={{
                                            pathname: SpaRoutes.CreateAccount.Private.BankId.Start,
                                            search: location.search,
                                        }}
                                    />
                                }
                            />
                        </Routes>
                    </>
                }
            />
        </Routes>
    );
};

export default CreateNewAccountPrivate;
