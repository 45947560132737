import { Button } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { accountApi } from '../../common/api/api.accounts';
import type { ApiException } from '../../common/api/api.types';
import { getCoopseLoginUrl, regex } from '../../common/utils';
import { PasswordStrengthIndicator } from '../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import TextInputRHF from '../../components/TextInputRHF/TextInputRHF';

interface FormFields {
    Password: string;
    ConfirmPassword: string;
}

const ResetPassword = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const code = searchParams.get('code');
    const { handleSubmit, formState, watch, getValues, control } = useForm<FormFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });

    const resetPasswordClick = (form: FormFields) => {
        if (formState.isValid && email && code) {
            resetPasswordMutation.mutate({
                email,
                code,
                confirmPassword: form.ConfirmPassword,
                password: form.Password,
            });
        }
    };
    const resetPasswordMutation = useMutation<
        void,
        ApiException,
        {
            password: string;
            confirmPassword: string;
            email: string;
            code: string;
        }
    >({
        mutationFn: (params) =>
            accountApi.resetPassword({
                email: params.email,
                code: params.code,
                password: params.password,
                confirmPassword: params.confirmPassword,
            }),
    });
    React.useEffect(() => {
        document.title = 'Coop | Återställ lösenord';
    }, []);

    if (resetPasswordMutation.isSuccess) {
        return (
            <div className="App-content">
                <h1 className="Heading Heading--large u-textCenter">Återställ lösenord</h1>
                <p className="u-textCenter u-marginTlg">
                    Ditt lösenord för <strong className="u-textNoWrap">{email}</strong> är nu
                    ändrat.
                </p>
                <p className="u-textCenter">Glöm inte att anteckna ditt lösenord.</p>
                <p className="u-textCenter">
                    <a className="Link" href={getCoopseLoginUrl()}>
                        Gå vidare till Coop.se
                    </a>
                </p>
            </div>
        );
    }

    if (!email || !code) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Återställ lösenord</h1>
                <p className="Notice Notice--red">
                    Länken du kom hit med verkar inte vara giltig längre, försök igen. Felkod 3222.
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter u-marginBlg">Återställ lösenord</h1>
            <form className="Form" onSubmit={handleSubmit(resetPasswordClick)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTmd"
                            label="Nytt lösenord"
                            type="password"
                            autoComplete="off"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    defaultValue=""
                    name="Password"
                    rules={{
                        required: 'Ange lösenord.',
                        pattern: {
                            value: regex.password,
                            message: 'Lösenordet måste bestå av minst 6 tecken.',
                        },
                    }}
                    control={control}
                />
                <PasswordStrengthIndicator className="u-marginTmd" value={watch('Password')} />
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTxsm"
                            label="Bekräfta nytt lösenord"
                            type="password"
                            autoComplete="off"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    defaultValue=""
                    name="ConfirmPassword"
                    rules={{
                        required: 'Ange lösenord.',
                        validate: (value) =>
                            value === getValues('Password') || 'Lösenorden matchar inte varandra.',
                    }}
                    control={control}
                />

                <footer className="u-marginTauto">
                    {resetPasswordMutation.isError && (
                        <p className="Notice Notice--red  u-marginTmd">
                            {resetPasswordMutation.error.friendlyMessage}
                        </p>
                    )}
                    <Button
                        disabled={resetPasswordMutation.isPending || !formState.isValid}
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        type="submit"
                    >
                        Ändra lösenord
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default ResetPassword;
