import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import useLoginContext from '../../../hooks/useLoginContext';
import { SpaRoutes } from '../../../hooks/useLoginNavigate';
import CreateEmailAccount from './e-post/CreateEmailAccount';
import VerifyEmail from './e-post/verifiera-epost/Verifymail';

const CreateNewAccountCompany = () => {
    const location = useLocation();
    const context = useLoginContext();
    React.useEffect(() => {
        context?.setShowNavigation(true);
        context?.setBackUrl(SpaRoutes.Login.Company);
    }, [context]);
    return (
        <Routes>
            <Route path="/e-post/verifiera" element={<VerifyEmail />} />
            <Route path={'/e-post/*'} element={<CreateEmailAccount />} />
            <Route
                path={'/*'}
                element={
                    <Navigate
                        to={{
                            pathname: SpaRoutes.CreateAccount.Company.Start,
                            search: location.search,
                        }}
                    />
                }
            />
        </Routes>
    );
};

export default CreateNewAccountCompany;
