import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { accountApi } from '../../../../common/api/api.accounts';
import type { AccountsDto } from '../../../../common/api/api.bankid';
import type { ApiException } from '../../../../common/api/api.types';
import BankId from '../../../../components/BankId/BankId';
import { useBankid } from '../../../../components/BankId/BankId.hooks';
import useLoginNavigate, { SpaRoutes } from '../../../../hooks/useLoginNavigate';

const Authenticate = () => {
    const navigate = useLoginNavigate();
    const onBankidSuccess = () => {
        getAccountsMutation.mutate();
    };
    const getAccountsMutation = useMutation<AccountsDto[], ApiException, void>({
        mutationFn: accountApi.getBankidUserAccounts,
        onSuccess: (accounts) => {
            // if you already got an email, just login with that one
            if (accounts.length) {
                navigate(SpaRoutes.Login.RememberMe);
            } else {
                navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
            }
        },
    });
    const {
        start,
        cancel,
        isStarted,
        isCollecting,
        progress,
        message,
        errorMessage,
        autoStartToken,
        qrCode,
    } = useBankid({
        isConfirm: false,
        onSuccess: onBankidSuccess,
    });
    return (
        <>
            <footer className="u-marginTauto">
                <p className="u-textXSmall u-colorGray u-marginTmd u-textCenter">
                    Genom att skapa ett kundkonto godkänner du{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/globala-sidor/anvandarvillkor/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Coops villkor
                    </a>
                    .{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/Globala-sidor/Om-webbplatsen/personuppgifter/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Klicka här
                    </a>{' '}
                    för att läsa om hur Coop hanterar din personliga information.
                </p>
            </footer>
            {getAccountsMutation.isError && (
                <p className="Notice Notice--red">
                    Ett fel inträffade vid inloggning. Prova starta om.
                </p>
            )}

            <BankId
                start={start}
                cancel={cancel}
                disabled={getAccountsMutation.isPending}
                isStarted={isStarted}
                isCollecting={isCollecting}
                progress={progress}
                message={message}
                errorMessage={errorMessage}
                autoStartToken={autoStartToken}
                qrCode={qrCode}
            />
        </>
    );
};

export default Authenticate;
